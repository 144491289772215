.button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.button.primary {
    background-color: #3b82f6;
    color: white;
    border: none;
}

.button.primary:hover {
    background-color: #2563eb;
}

.button.secondary {
    background-color: transparent;
    color: #3b82f6;
    border: 1px solid #3b82f6;
}

.button.secondary:hover {
    background-color: #3b82f6;
    color: white;
}

.button.primary.submit {
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 0.75rem 1rem;
}

.button.primary.submit:hover {
    background-color: #2563eb;
}
.input {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem 0 0 0.25rem;
    outline: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.input::placeholder {
    color: #9ca3af;
}
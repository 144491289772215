/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  background-color: #f8f9fa;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #1a202c;
}

/* Header styles */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #1a202c;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.logo-icon {
  width: 24px;
  height: 24px;
  color: #60a5fa;
}

.logo-text {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav {
  display: flex;
  gap: 1.5rem;
}

.nav-link {
  color: #e5e7eb;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: white;
}

/* Hero section styles */
.hero {
  background-color: #1a202c;
  color: white;
  padding: 6rem 0;
  text-align: center;
}

.hero-title {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.hero-description {
  max-width: 700px;
  margin: 0 auto 2rem;
  color: #9ca3af;
  font-size: 1.25rem;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Services section styles */
.services {
  background-color: #f8f9fa;
  padding: 6rem 0;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2.5rem;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service-icon {
  width: 48px;
  height: 48px;
  color: #60a5fa;
  margin-bottom: 1rem;
}

.service-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #1a202c;
}

.service-description {
  color: #4b5563;
  max-width: 300px;
}

/* Features section styles */
.features {
  background-color: white;
  padding: 6rem 0;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feature-icon {
  width: 40px;
  height: 40px;
  color: #60a5fa;
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  color: #1a202c;
}

.feature-description {
  color: #4b5563;
  font-size: 0.875rem;
}

/* Contact section styles */
.contact {
  background-color: #1a202c;
  color: white;
  padding: 6rem 0;
  text-align: center;
}

.contact-description {
  max-width: 600px;
  margin: 0 auto 2rem;
  color: #9ca3af;
}

.contact-form {
  display: flex;
  max-width: 400px;
  margin: 0 auto;
}

/* Footer styles */
.footer {
  background-color: #ffffff;
  color: #4b5563;
  padding: 1.5rem 0;
  border-top: 1px solid #e5e7eb;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-text {
  font-size: 0.875rem;
  margin: 0;
}

.footer-nav {
  display: flex;
  gap: 1.5rem;
}

.footer-link {
  font-size: 0.875rem;
  color: #4b5563;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #1a202c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }

  .services-grid,
  .features-grid {
    grid-template-columns: 1fr;
  }

  .footer .container {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 640px) {
  .footer .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .footer-nav {
    margin-top: 0.5rem;
  }
}